import * as React from "react";
import "../styles/main.scss";
import { StaticImage } from "gatsby-plugin-image";
import { useState, useEffect } from "react";
import myCV from "../downloads/cv.pdf";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  const data = {
    name: "Zaky Arifudin",
    description: `Halo, Saya adalah Apps & Web developer yang dalam 2 tahun terakhir 
      berfokus pada frontend React Native & React JS. Dapat bekerja dalam tim. 
      Memiliki pengalaman dalam mengembangkan apps & web pada marketplace dan software akuntansi
    `,
  };

  const experiences = [
    {
      project_name: "Icube By Sirclo",
      job_time: "Sep 2021 - Sekarang",
      software_type: "Marketplace",
      tag: "Kerja",
      position: "React Native & React JS Developer",
      project_description: `Membangun dan mengembangkan marketplace dengan basis magento 2.0 dengan 
      bagian frontend : Mobile Apps, Website & POS
      `,
      project_detail: `Mobile Apps menggunakan React Native : DatascripMall.ID, Guardian ID, LOTTEmart mall, Kino, Swift App (Produk Icube).
       Website menggunakan Next JS (SSR) : Biofarma (Medbiz.id), men-support website : 9to9 dan exsport.
       POS menggunakan React JS murni (CSR) : ABA Group (Loccitane, Venchi dan Elemis).
      `,
      link: "https://www.sirclo.com/",
      link_name: "sirclo.com",
    },
    {
      project_name: "Equal System",
      software_type: "Akuntansi",
      job_time: "Jan 2019 - Mar 2021 (2 Tahun 3 Bulan)",
      tag: "Kerja",
      position: "Fullstack Developer : Laravel PHP & React JS",
      project_description: `EQUAL System adalah software akuntansi yang memudahkan proses 
        pembukuan dan pengelolaan bisnis dalam satu aplikasi. Menghasilkan laporan 
        keuangan dalam waktu yang singkat.
      `,
      project_detail: `Proyek yang dikerjakan meliputi modul 
        Setup (User, Akses User, Pelanggan, Pemasok, Satuan, Item, Item Grouping, Karyawan, 
        Kas/Bank, Lokasi, Gudang, Departemen, import excel semua setup, dll), 
        Aset Tetap (Disposal Aset, Transfer Aset, Pemeliharaan Aset, Depresiasi Aset), 
        Persediaan (Job Costing , Job Cost Rollover), Buku Besar,
        Finansial (Manajemen Kas, Kas Kecil, Transfer Antar Bank, Debit Memo, Kredit Memo),
        Integrasi Pembelian & Penjualan (DO: Pengiriman Pesanan – PR: Penerimaan Pesanan dan 
        PT:Retur Pembelian - ST: Retur Penjualan), 
        Laporan-laporan (Balance Sheet, Profit/Loss, Inventory dan lain-lain), 
        Item Batch & Serial, Optimasi transaksi back-date,
        Penjadwalan antrian untuk auto-confirm pada Sales Invoice POS
        . 
      `,
      link: "https://equalsystem.io/",
      link_name: "equalsystem.io",
    },
    {
      project_name: "Sistem Keuangan Sekolah",
      software_type: "Keuangan",
      job_time: "Sep 2018 - Des 2018 (4 bulan)",
      tag: "Magang",
      position: "Backend Developer : Laravel PHP",
      project_description: `Sistem yang dapat melakukan pencatatan staf, orang tua, murid, dan 
        setiap user dapat memiliki saldo yang dapat digunakan untuk  transaksi keuangan di 
        sekolah seperti spp bulanan, uang gedung, biaya program kursus, 
        biaya program ekstrakurikuler, biaya program liburan, dan transaksi lainnya. 
      `,
      project_detail: `Proyek yang dikerjakan meliputi modul Dashboard, Staff, Uang Gedung, Tahun Ajaran,
        Tingkat dan Kelas, Ruang Kelas , Program Sekolah (Ekstrakurikuler, Kursus dan Liburan), Laporan.
      `,
      link: "https://technopartner.id/",
      link_name: "technopartner.id",
    },
    {
      project_name: "CRM-POS",
      software_type: "Penjualan",
      job_time: "Jun 2018 - Agu 2018 (2 bulan)",
      tag: "Magang",
      position: "Backend Developer : Laravel PHP",
      project_description: `CRM-POS adalah sistem penyewaan untuk manajemen penjualan makanan 
        yang tersedia dalam bentuk web dan apikasi mobile. Web berkaitan dengan manajemen 
        perusahaan sedangkan untuk aplikasi mobile berkaitan dengan pemesanan makanan. 
      `,
      project_detail: `Proyek yang dikerjakan meliputi modul User, User Outlet, 
        Fitur User , Outlet, Perusahaan, Paket, 
        Voucher, Permintaan Langganan, Pembayaran Manual,
        Pemesanan, Dashboard.
      `,
      link: "https://technopartner.id/",
      link_name: "technopartner.id",
    },
  ];

  const [isActiveExperince, setIsActiveExperince] = useState(null);
  const [isActiveNav, setIsActiveNav] = useState(true);

  const toggleExperienceClass = (experience) => {
    let active = experience;
    if (active === isActiveExperince) {
      active = null;
    }
    setIsActiveExperince(active);
  };

  useEffect(() => {
    let cardGrid = document.querySelector(".card-grid");
    let cardGrid2 = document.querySelector(".card-grid-2");
    let layout4 = document.querySelector(".layout-4");

    // Berguna untuk ngasih animasi saat di-scroll dan class tampil
    const observer = new IntersectionObserver(
      (entries) => {
        // layout 2
        const headTitle2 = document.querySelector(".head-title-2");
        const cards = document.getElementsByClassName("card-item-border");

        const headTitle3 = document.querySelector(".head-title-3");
        const cardGrid2 = document.querySelector(".card-grid-2");

        const headTitle4 = document.querySelector(".head-title-4");
        const myCV = document.querySelector(".my-cv");

        entries.forEach((entry) => {
          // console.log(entry.target.className, entry.isIntersecting);

          // Start - Layout 2
          if (
            entry.target.className === "card-grid" &&
            entry.isIntersecting === true
          ) {
            headTitle2.classList.add("head-title-2-anim");
            for (let i = 0; i < cards.length; ++i) {
              cards[i].classList.add("card-item-border-anim");
            }
            return;
          }

          if (
            entry.target.className === "card-grid" &&
            entry.isIntersecting === false
          ) {
            headTitle2.classList.remove("head-title-2-anim");
            for (let i = 0; i < cards.length; ++i) {
              cards[i].classList.remove("card-item-border-anim");
            }
            return;
          }

          // End - Layout 2

          // Start - Layout 3
          if (
            entry.target.className === "card-grid-2" &&
            entry.isIntersecting === true
          ) {
            headTitle3.classList.add("head-title-3-anim");
            cardGrid2.classList.add("card-grid-2-anim");
            return;
          }

          if (
            entry.target.className === "card-grid-2 card-grid-2-anim" &&
            entry.isIntersecting === false
          ) {
            headTitle3.classList.remove("head-title-3-anim");
            cardGrid2.classList.remove("card-grid-2-anim");
            return;
          }

          // End - Layout 3

          // Start - Layout 4
          if (
            entry.target.className === "layout-4" &&
            entry.isIntersecting === true
          ) {
            headTitle4.classList.add("head-title-4-anim");
            myCV.classList.add("my-cv-anim");
            return;
          }

          if (
            entry.target.className === "layout-4" &&
            entry.isIntersecting === false
          ) {
            headTitle4.classList.remove("head-title-4-anim");
            myCV.classList.remove("my-cv-anim");
            return;
          }
        });
      },
      { threshold: [0, 1] } // visibilty elementnya
    );

    observer.observe(cardGrid);
    observer.observe(cardGrid2);
    observer.observe(layout4);
  }, []);

  const onClickNav = () => {
    setIsActiveNav(!isActiveNav);
    const navBorder = document.querySelector(".nav-border");
    if (isActiveNav === false) {
      navBorder.style.transform = "scale(0)";
      navBorder.style.transformOrigin = "top left";
      navBorder.style.transition = "all 0.6s ease-in";
    } else {
      navBorder.style.transform = "scale(1)";
      // navBorder.style.transformOrigin = "bottom right";
      navBorder.style.transition = "all 0.6s ease-out";
    }
  };

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zaky Arifudin | Portfolio</title>
        <meta name="description" content="Zaky Arifudin Portfolio Website" />
        <meta name="author" content="Zaky Arifudin" />
      </Helmet>
      <div className="container">
        <div className="layout-1" id="nav-layout-1">
          <div className="head-name">
            <ul>
              <li className="head-left">
                <div
                  className={isActiveNav ? "logo-z" : "logo-z logo-z-anim"}
                  onClick={() => onClickNav()}
                  onKeyPress={() => onClickNav()}
                  role="button"
                  tabIndex={0}
                >
                  <div className="logo-z-1"></div>
                  <div className="logo-z-2"></div>
                  <div className="logo-z-3"></div>
                  <div className="logo-z-4"></div>
                  <div className="logo-z-5"></div>
                  <div className="logo-z-6"></div>
                  <div className="logo-z-7"></div>
                  <div className="logo-z-8"></div>
                  <div className="logo-z-9"></div>
                </div>
                <div className="nav-border">
                  <div className="nav">
                    <ul className="nav-ul">
                      <li>
                        <a
                          className="nav-child"
                          href="#nav-layout-1"
                          onClick={() => onClickNav()}
                        >
                          Beranda
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-child"
                          href="#nav-layout-2"
                          onClick={() => onClickNav()}
                        >
                          Kemampuan
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-child"
                          href="#nav-layout-3"
                          onClick={() => onClickNav()}
                        >
                          Pengalaman
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-child"
                          href="#nav-layout-4"
                          onClick={() => onClickNav()}
                        >
                          Bonus
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="head-left">
                <div className="my-name">{data.name}</div>
              </li>

              <li className="head-right">
                <a
                  href="https://github.com/zakyarifudin?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../images/github.png"
                    alt="Github"
                    placeholder="blurred"
                    layout="fixed"
                    width={25}
                    height={25}
                  />
                </a>
              </li>
              <li className="head-right">
                <a
                  href="https://www.linkedin.com/in/zaky-arifudin-a50183191"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../images/linkedin.png"
                    alt="Linked in"
                    placeholder="blurred"
                    layout="fixed"
                    width={25}
                    height={25}
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="anim-grid">
            <div className="anim-z">
              <div className="anim-z-1"></div>
              <div className="anim-z-2"></div>
              <div className="anim-z-3"></div>
              <div className="anim-z-4"></div>
              <div className="anim-z-5"></div>
              <div className="anim-z-6"></div>
              <div className="anim-z-7"></div>
              <div className="anim-z-8"></div>
              <div className="anim-z-9"></div>
            </div>
            <div className="anim-a">
              <div className="anim-a-1"></div>
              <div className="anim-a-2"></div>
              <div className="anim-a-3"></div>
              <div className="anim-a-4"></div>
              <div className="anim-a-5"></div>
              <div className="anim-a-6"></div>
              <div className="anim-a-7"></div>
              <div className="anim-a-8"></div>
              <div className="anim-a-9"></div>
            </div>
            <div className="anim-k">
              <div className="anim-k-1"></div>
              <div className="anim-k-2"></div>
              <div className="anim-k-3"></div>
              <div className="anim-k-4"></div>
              <div className="anim-k-5"></div>
              <div className="anim-k-6"></div>
              <div className="anim-k-7"></div>
              <div className="anim-k-8"></div>
              <div className="anim-k-9"></div>
            </div>
            <div className="anim-y">
              <div className="anim-y-1"></div>
              <div className="anim-y-2"></div>
              <div className="anim-y-3"></div>
              <div className="anim-y-4"></div>
              <div className="anim-y-5"></div>
              <div className="anim-y-6"></div>
              <div className="anim-y-7"></div>
              <div className="anim-y-8"></div>
              <div className="anim-y-9"></div>
            </div>
          </div>
          <div className="my-description-border">
            <div className="my-description">
              <p>" {data.description} "</p>
            </div>
          </div>
        </div>

        <div className="divider-layout" />

        <div className="layout-2" id="nav-layout-2">
          <div className="head-name-2">
            <div className="head-title-2">
              <h1>Kemampuan</h1>
              <div className="divider-loading-2" />
            </div>
          </div>

          <div className="card-grid">
            <div className="card-item-border">
              <div className="card-item">
                <div className="card-item-image">
                  <StaticImage
                    src="../images/react-native.png"
                    alt="React Native"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h2>React Native</h2>
                <p>Mobile Apps</p>
              </div>
            </div>
            <div className="card-item-border">
              <div className="card-item">
                <div className="card-item-image">
                  <StaticImage
                    src="../images/reactjs.png"
                    alt="React JS"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h2>React JS</h2>
                <p>Next JS & React</p>
              </div>
            </div>
            <div className="card-item-border">
              <div className="card-item">
                <div className="card-item-image">
                  <StaticImage
                    src="../images/laravel.png"
                    alt="Laravel"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h2>PHP</h2>
                <p>Laravel</p>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-layout" />

        <div className="layout-3" id="nav-layout-3">
          <div className="head-name-3">
            <div className="head-title-3">
              <h1>Pengalaman</h1>
              <div className="divider-loading-3" />
            </div>
          </div>
          <div className="card-grid-2">
            {experiences.map((experience, index) => {
              const id = `experience${index}`;
              let headStyle = "card-item-2";
              let bodyStyle = "card-item-body-2";

              // atas
              if (index === 0) {
                headStyle += " card-item-2-top";
                if (isActiveExperince === id) {
                  headStyle += " card-item-2-border";
                  bodyStyle += " card-item-body-2-collapse";
                }
              }
              // bawah
              else if (index === experiences.length - 1) {
                if (isActiveExperince === id) {
                  headStyle += " card-item-2-border";
                  bodyStyle += " card-item-body-2-collapse card-item-2-bottom";
                } else {
                  headStyle += " card-item-2-bottom";
                }
              }
              // tengah2
              else {
                if (isActiveExperince === id) {
                  headStyle += " card-item-2-border";
                  bodyStyle += " card-item-body-2-collapse";
                }
              }

              return (
                <>
                  <div
                    className={headStyle}
                    onClick={() => toggleExperienceClass(id)}
                    onKeyPress={() => toggleExperienceClass(id)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="card-item-title-2">
                      <h2>{experience.project_name}</h2>
                    </div>
                    <div className="card-item-desc-2">
                      <h3>{experience.software_type}</h3>
                    </div>
                    <div
                      className={
                        experience.tag === "Kerja"
                          ? "tag tag-work"
                          : "tag tag-internship"
                      }
                    >
                      <p>{experience.tag}</p>
                    </div>
                  </div>

                  <div className={bodyStyle}>
                    <div className="card-item-body-2-as-job">
                      <h4>{experience.position}</h4>
                      <h5 style={{ textAlign: "center" }}>
                        {experience.job_time}
                      </h5>
                    </div>
                    <div className="card-item-body-2-desc">
                      <h3>{experience.project_description}</h3>
                    </div>
                    <div className="card-item-body-2-desc">
                      <h3>{experience.project_detail}</h3>
                    </div>
                    <a href={experience.link} target="_blank" rel="noreferrer">
                      {experience.link_name}
                    </a>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="divider-layout" />

        <div className="layout-4" id="nav-layout-4">
          <div className="head-name-4">
            <div className="head-title-4">
              <h1>Bonus</h1>
              <div className="divider-loading-4" />
            </div>
          </div>

          <div className="my-cv">
            <a href={myCV} target="_blank" rel="noreferrer">
              <div className="my-cv-border">
                <div className="my-cv-body">
                  <h2>CV Saya</h2>
                </div>
              </div>
            </a>
          </div>
          <div className="anim-main">
            <div className="anim-1"></div>
            <div className="anim-2"></div>
            <div className="anim-3"></div>
            <div className="anim-4"></div>
            <div className="anim-5"></div>
            <div className="anim-6"></div>
            <div className="anim-7"></div>
            <div className="anim-8"></div>
            <div className="anim-9"></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
